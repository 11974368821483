<template>
  <div class="attendanceConfig">
    <AttendanceMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称" style="margin-bottom: 0">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="考勤模式">
          <span
            class="filterType"
            v-if="currentAttendanceMode == 0 || currentAttendanceMode == 1"
            @click="attendanceMode = 1"
            :class="[attendanceMode == 1 ? 'filterTypeActive' : '']"
            >人脸考勤控制器</span
          >
          <span
            class="filterType"
            v-if="currentAttendanceMode == 0 || currentAttendanceMode == 2"
            @click="attendanceMode = 2"
            :class="[attendanceMode == 2 ? 'filterTypeActive' : '']"
            >云筑网接口</span
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank"></div>

    <!-- 云筑网配置面板 -->
    <div class="wrap" v-if="attendanceMode == 2">
      <el-form label-width="100px" style="width: 500px">
        <el-form-item label="appSecret">
          <el-input size="small" v-model="yzwConfig.appsecret"></el-input>
        </el-form-item>
        <el-form-item label="ProjectCode">
          <el-input size="small" v-model="yzwConfig.projectCode"></el-input>
        </el-form-item>
        <el-form-item label="appid">
          <el-input size="small" v-model="yzwConfig.appid"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10 }"
            size="small"
            v-model="yzwConfig.remarks"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="saveYzwConfig"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 考勤机配置面板 -->
    <div class="wrap" v-if="attendanceMode == 1">
      <el-table :data="faceDeviceList" style="width: 100%">
        <el-table-column
          prop="fmName"
          label="控制器名称"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="fmSn"
          label="控制器SN"
          width="180"
        ></el-table-column>
        <el-table-column prop="fmSite" label="安装地点"></el-table-column>
        <el-table-column prop="remark" label="控制器类型">
          <template slot-scope="scope">
            <span v-if="scope.row.fmInout==0">出门</span>
            <span v-if="scope.row.fmInout==1">进门</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="简介"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="deleteFaceDevice(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getFaceDeviceList"
        @size-change="getFaceDeviceList"
      >
      </el-pagination>
    </div>

    <div class="wrap-blank" v-if="attendanceMode == 1"></div>
    <div class="wrap" v-if="attendanceMode == 1">
      <el-form label-width="100px" style="width: 500px">
        <el-form-item label="控制器名称">
          <el-input size="small" v-model="faceDevice.fmName"></el-input>
        </el-form-item>
        <el-form-item label="控制器SN">
          <el-input size="small" v-model="faceDevice.fmSn"></el-input>
        </el-form-item>
        <el-form-item label="安装地点">
          <el-input size="small" v-model="faceDevice.fmSite"></el-input>
        </el-form-item>
        <el-form-item label="控制器类型">
          <el-radio-group v-model="faceDevice.fmInout">
            <el-radio :label="0">出门</el-radio>
            <el-radio :label="1">进门</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
             v-model="faceDevice.remark"
            :autosize="{ minRows: 5, maxRows: 10 }"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="saveFaceDevice">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<style lang="less" scope>
.attendanceConfig {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>

<script>
import AttendanceMenu from "@/components/AttendanceMenu.vue";

export default {
  name: "AttendanceConfig",
  components: {
    AttendanceMenu,
  },
  data() {
    return {
      currentProject: null,
      projectList: [],
      attendanceMode: 1,
      yzwConfig: {
        appsecret: null,
        projectCode: null,
        appid: null,
        remarks: null,
      },
      faceDevice: {
        fmid: null, // 人脸机主键 id
        fmName: null, // 设备名称
        fmSn: null, // 人脸设备 mac 码
        fmSite: null, // 签到地点
        fmInout: 0, // 进出门标识，0出门 1进门
        remark: null, // 备注
      },
      faceDeviceList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

    };
  },
  computed: {
    currentAttendanceMode: function () {
      if (!this.projectList.length) {
        return;
      }
      var data;
      this.projectList.forEach((item) => {
        if (item.id == this.currentProject) {
          data = item.attendanceSet;
        }
      });
      return data;
    },
  },
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.yzwConfig = {
        appsecret: null,
        projectCode: null,
        appid: null,
        remarks: null,
      };
      if (this.currentAttendanceMode == 0) {
        this.attendanceMode = 1;
      }
      if (this.currentAttendanceMode == 1) {
        this.attendanceMode = 1;
      }
      if (this.currentAttendanceMode == 2) {
        this.attendanceMode = 2;
      }
      if (this.currentAttendanceMode == 1 || this.currentAttendanceMode == 0) {
        this.getFaceDeviceList(); // 加载人脸设备列表
      }
      if (this.currentAttendanceMode == 2) {
        this.getYzwConfig(); // 加载云筑网配置信息
      }
    },
    currentAttendanceMode: function (val) {
      if (val == 1 || val == 0) {
        this.getFaceDeviceList(); // 加载人脸设备列表
      }
      if (val == 2) {
        this.getYzwConfig(); // 加载云筑网配置信息
      }
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载云筑网配置信息
    getYzwConfig: function () {
      var that = this;
      var data = {
        projectId: this.currentProject,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/yzwConfiguration/getById",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getYzwConfig =>", res);
          if (!res.data) {
            that.yzwConfig = {
              appsecret: null,
              projectCode: null,
              appid: null,
              remarks: null,
            };
          }
          that.yzwConfig = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 保存云筑网配置中心
    saveYzwConfig: function () {
      var that = this;
      var data = {
        id: this.yzwConfig.id,
        projectId: this.yzwConfig.projectId,
        projectName: this.yzwConfig.projectName,
        appsecret: this.yzwConfig.appsecret,
        projectCode: this.yzwConfig.projectCode,
        appid: this.yzwConfig.appid,
        status: this.yzwConfig.status,
        remarks: this.yzwConfig.remarks,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/yzwConfiguration/insertOrupdate",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("saveYzwConfig =>", res);
          that.$notify({
            title: "成功",
            message: "操作已成功。",
            type: "success",
          });
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取人脸考勤设备列表
    getFaceDeviceList: function () {
      var that = this;
      var data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        projectId: this.currentProject,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgfacemachine/WgFaceMachinePage",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getFaceDeviceList =>", res);
          that.faceDeviceList = res.data.wgFaceMachineList;
          that.totals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 保存人脸设备
    saveFaceDevice: function () {
      var that = this;
      var data = {
        projectId: this.currentProject,
        fmid: this.faceDevice.fmid,
        fmName: this.faceDevice.fmName,
        fmSn: this.faceDevice.fmSn,
        fmSite: this.faceDevice.fmSite,
        fmInout: this.faceDevice.fmInout,
        remark: this.faceDevice.remark,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgfacemachine/saveOrEdit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("saveFaceDevice =>", res);
          that.faceDevice = {
            fmid: null, // 人脸机主键 id
            fmName: null, // 设备名称
            fmSn: null, // 人脸设备 mac 码
            fmSite: null, // 签到地点
            fmInout: 0, // 进出门标识，0出门 1进门
            remark: null, // 备注
          }
          that.getFaceDeviceList()
          that.$notify({
            title: "成功",
            message: "操作已成功。",
            type: "success",
          });
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    //  删除人脸设备
    deleteFaceDevice: function (data) {
      var that = this;
      var data = {
        fmid: data.fmid,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgfacemachine/deleteById",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("deleteFaceDevice =>", res);
          that.$notify({
            title: "成功",
            message: "操作已成功。",
            type: "success",
          });
          that.getFaceDeviceList()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    }
  },
};
</script>
